body{
  scrollbar-color: grey black;
}

.App {
  text-align: center;
}

::-webkit-scrollbar {
    width: 10.5px;
    max-height: 25%;
}
 
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow:inset 0 0 6px rgba(0,0,0,0.5);
    background:#3e2754;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
