.blogs {
  margin-top: 5rem;
}

.blog-avatar {
  object-fit: cover;
  height: 95%;
}

.blogs .card-image {
  max-height: 20%;
}

.blog-title {
  color: #3e2754;
}

.blogs
  .card
  .card-action
  a:not(.btn):not(.btn-large):not(.btn-small):not(.btn-large):not(.btn-floating) {
  color: #3e2754;
  font-weight: 600;
}

@media screen and (max-width: 650px) {
  .blogs h1 {
    font-size: 40px;
  }
}

@media screen and (max-width: 1680px) {
  .blogs .card .card-image .card-title {
    font-family: "Jost", sans-serif;
    color: #ffffff;
    text-shadow: 2px 4px 6px rgba(61, 26, 16, 0.79);
  }
}
